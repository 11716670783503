import React from "react";
// import { Link } from "gatsby"

import { Container, Row, Col } from "react-bootstrap";

import Layout from "../components/layout";
// import Image from "../components/image"
import SEO from "../components/seo";
import Banner from "../components/sections/banner";
import Blog from "../components/sections/blogs";
import HomeHub from "../components/sections/hubs/home-hub";

import vostronet_icon from "../images/vostronet-icon.svg";
import { graphql, useStaticQuery } from "gatsby";

const bgQuery = graphql`query {
  file(relativePath: { eq: "headers/home_header.png"}) {
    childImageSharp {
      fluid(quality: 90, maxWidth: 4160) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}`

const IndexPage = () => {
  const bgData = useStaticQuery(bgQuery);
  return (<Layout title={"Smarter Internet"}>
    <SEO title="VostroNet - Smarter Internet - Internet Provider">
      <meta name="description" content="VostroNet is the leading end-to-end provider of fibre-to-the-premises and Wi-Fi networks for multi-dwelling developments, delivering smarter internet."/>
    </SEO>
    <Banner bgData={bgData} className="font-white" data={{
      title: {
        name: "Smarter Internet",
      },
      subtitles: [
        {
          name: `VostroNet is a leading network owner and internet provider in the Asia-Pacific region`,
        },
        {
          name: `We deliver high-speed fibre-to-the-premises and Wi-Fi networks for multi-dwelling developments, on our own fibre network`,
        }
      ],
      buttonData: {
        name: "Explore",
      },
    }} />
    <HomeHub />
    <div className="bg-blue vostronet-diff general py-5 mt-5 font-white">
      <Container>
        <Row>
          <Col>
            <div className="title font-white mb-5">The VostroNet Difference</div>
          </Col>
        </Row>
        <a href="/about-us/">
          <Row className="border-white px-3 py-4">
            <Col xs={12} md={8}>
              <div className="desc font-white pb-4">
                {"We have successfully redesigned how the Internet Provider deploys its network to deliver high-speed, quality and affordability. We lead the industry in software-defined networks, harnessing software to optimise performance and user experience."}
              </div>
              <button href="/about-us/" rel="noopener" target="_blank" className="btn btn-green">
                Read More
            </button>
            </Col>
            <Col xs={12} md={4}>
              <img src={vostronet_icon} alt="vostronet_icon" className="icon block-center" />
            </Col>
          </Row>
          </a>
        </Container>
    </div>
    <Blog  />
  </Layout>);
}

export default IndexPage
