import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "gatsby";
import SecurityOnNetworkImage from "../../../images/main-menu/securityonyournetwork_button.png";
import ManagedInternetImage from "../../../images/main-menu/managed_internet_button.png";
import StudentAccommImage from "../../../images/main-menu/studentaccomm_button.png";
import ResidentialFibreImage from "../../../images/main-menu/residentialfibernetwork_button.png";
import DataEnterpriseImage from "../../../images/main-menu/datanetworkent_button.png";
import OurInfrastructureImage from "../../../images/main-menu/our_infrastructure.png";
import SoftwarePlatformImage from "../../../images/main-menu/softwareplatform_button.png";


const HomeHub = () => {
  return (
    <Container className="py-5">
      <Container fluid className="hub home-hub">
        <Row>
          <Col xs={12} lg={3}>
            <Link to="/about-us/" className="hub-container">
              <div className="tall" alt="The VostroNet Advantage" style={{
                backgroundImage: `url(${SecurityOnNetworkImage})`,
              }} />
              <span className="hub-text tall-bottom">
                <div>
                  {"The VostroNet"}
                </div>
                <div>
                  {"Advantage"}
                </div>
              </span>
              <div className="hover-info">
                <div>
                  {"The VostroNet"}
                </div>
                <div>
                  {"Advantage"}
                </div>
                <div className="subtitle mb-3">
                  {"VostroNet is an award-winning company with a rapidly growing network portfolio."}
                </div>
                <Button variant="green" size="short">
                  {"Explore"}
                </Button>
              </div>
            </Link>
          </Col>
          <Col xs={12} lg={9}>
            <Row>
              <Col xs={12}>
                <Link to="/internet-environments/" className="hub-container">
                  <div className="long" style={{
                    backgroundImage: `url(${ManagedInternetImage})`,
                  }} />
                  <span className="hub-text">
                    <div>
                      {"Internet Environments"}
                    </div>
                  </span>
                  <div className="hover-info">
                    <div className="title mb-2">
                      {"Internet Environments"}
                    </div>
                    <div className="subtitle mb-3">
                      {"Harnessing the latest wireless equipment, we deploy future-proof network solutions to a range of wireless environments."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Link to="/student-accommodation/" className="hub-container">
                  <div className="short" style={{
                    backgroundImage: `url(${StudentAccommImage})`,
                  }} />
                  <span className="hub-text tall-bottom">{"Internet for Students"}</span>
                  <div className="hover-info">
                    <div className="title mb-2">
                      {"Internet for Students"}
                    </div>
                    <div className="subtitle mb-3">
                      {"Deploying advanced and secure wireless environments for University Campus and Student Accommodation."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </Link>
              </Col>
              <Col xs={12} lg={4} className="show-front">
                <Link to="/residential-fibre-network/" className="hub-container">
                  <div className="tall" style={{
                    backgroundImage: `url(${ResidentialFibreImage})`,
                  }} />
                  <span className="hub-text bottom-0">
                    <div>
                      {"Residential"}
                    </div>
                    <div>
                      {"Fibre Network"}
                    </div>
                  </span>
                  <div className="hover-info">
                    <div className="title mb-2">
                      {"Residential Fibre Network"}
                    </div>
                    <div className="subtitle mb-3">
                      {"Established fibre network to residential developments across the Asia-Pacific."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </Link>
              </Col>
              <Col xs={12} lg={4} className="show-front">
                <Link to="/data-network-and-enterprise/" className="hub-container">
                  <div className="tall" style={{
                    backgroundImage: `url(${DataEnterpriseImage})`,
                  }} />
                  <span className="hub-text">
                    <div>
                      {"Data Network"}
                    </div>
                    <div>
                      {"& Enterprise"}
                    </div>
                  </span>
                  <div className="hover-info">
                    <div className="title mb-2">
                      {"Data Network & Enterprise"}
                    </div>
                    <div className="subtitle mb-3">
                      {"Advanced end-to-end fibre and ethernet network with an international footprint."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="stray-element">
            <Row>
              <Col xs={12} lg={3}>
                <Link to="/our-network/" className="hub-container">
                  <div className="short" style={{
                    backgroundImage: `url(${OurInfrastructureImage})`,
                  }} />
                  <span className="hub-text">{"Our Infrastructure"}</span>
                  <div className="hover-info">
                    <div className="title mb-2">
                      {"Our Infrastructure"}
                    </div>
                    <div className="subtitle mb-3">
                      {"VostroNet implements world-class infrastructure to deliver high-speed wired and wireless internet."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </Link>
              </Col>
              <Col xs={12} lg={3}>
                <Link to="/software-platform/" className="hub-container">
                  <div className="short" style={{
                    backgroundImage: `url(${SoftwarePlatformImage})`,
                  }} />
                  <span className="hub-text">{"Software Platform"}</span>
                  <div className="hover-info">
                    <div className="title mb-2">
                      {"Software Platform"}
                    </div>
                    <div className="subtitle mb-3">
                      {"An advanced software platform driving VostroNet's next generation software-defined network."}
                    </div>
                    <Button variant="green" size="short">
                      {"Explore"}
                    </Button>
                  </div>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default HomeHub;